import logger from "../../../utils/logger";
import ApiClient from "../client";

/**
 * The baseURL in the config is temporary until
 * we move all endpoints over to v6, and we can set
 * the baseURL in the axios instances which is in
 * the /client/client.js file
 */

const Member = {
  namespace: "me",

  basePath: "/v6.2/member",

  async getMemberProfile(token) {
    const endpoint = `${this.basePath}/me?source=Web`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        return null;
      }

      return {
        memberProfileInfo: resp.data,
      };
    } catch (error) {
      logger.error(`[me v6 api] -> ERROR ${error}`);
    }
  },

  async getMemberProfilePic(token, memberId) {
    const endpoint = `/v6/signed-assets/members/pictures?memberIDsStr=${memberId}&source=web`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[me v6 api] -> ERROR ${error}`);
    }
  },

  async updateMemberProfile(data, token) {
    const endpoint = `${this.basePath}/me`;

    try {
      const resp = await ApiClient._put(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        data,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        logger.warn(
          `[member me update v6.2 api] -> WARNING something went wrong`
        );
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[member me update v6.2 api] -> ERROR ${error}`);
      return error && error.response ? error.response.data : null;
    }
  },

  async getBillingInfo(token) {
    const endpoint = `${this.basePath}/billing-information`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        return null;
      }

      return {
        memberBillingInfo: resp.data,
      };
    } catch (error) {
      logger.error(`[billing v6 api] -> ERROR ${error}`);
    }
  },

  async getGiftcardDetails(token) {
    const endpoint = `${this.basePath}/giftcard-details`;

    try {
      const resp = await ApiClient._get(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`[billing v6 api] -> ERROR ${error}`);
    }
  },
};

export default Member;
